<div class="row mb-2">
  <div class="col-12">
    <strong>Patient Name</strong>
    {{order.patientFirstName}} {{order.patientLastName}}
  </div>
</div>
<div class="row mb-2">
  <div class="col-6">
    <strong>Patient DOB</strong>
    {{order.patientDob | date:'M/d/yyyy'}}
  </div>
  <div class="col-6">
    <strong>Gender</strong>
    {{order.patientGender}}
  </div>
</div>

<div class="row mb-2">
  <strong>Patient Address</strong>
  <div>
    {{order.patientStreetAddress}} {{order.patientStreetAddress2}}
  </div>
  <div class="row">
    <div class="col-5">{{order.patientCity}}, {{order.patientState}} {{order.patientZipcode}}</div>
  </div>
</div>

<div class="row mb-2">
  <strong>Shipping Address</strong>
  <div>
    {{order.shippingStreetAddress}}
  </div>
  <div class="row">
    <div class="col-5">{{order.shippingCity}}, {{order.shippingState}} {{order.shippingZipcode}} </div>
  </div>
</div>
<div class="row">
  <div class="col-5">{{order.patientEmail}}</div>
  <div class="col-5">{{order.patientPhone}}</div>
</div>
<div class="mb-2 mt-2">
  <strong>Prescriptions</strong>
  @for (drug of order.products; track drug) {
  <div class="row">
    <div class="col-10">
      {{drug.name}} {{drug.strength}} {{drug.form}} {{drug.count}} {{getQTY(drug.quantity)}} {{drug.medQuantity}} -
      {{drug.origin}}, {{drug.ndc}}
    </div>
    <div class="col-2">
      ${{drug.price}}
    </div>
  </div>
  }
</div>
<div class="row mb-2">
  <div class="col-5">
    <strong>Is Refill</strong>
    {{order.isRefillRequest}}
  </div>


  <div class="row mb-2">
    <strong>Physician Name</strong>
    <p>{{order.physicianFirstName}} {{order.physicianLastName}}</p>
    <strong>Physician Address</strong>
    <div>
      {{order.physicianStreetAddress}}
    </div>
    <div class="row">
      <div class="col-5">{{order.physicianCity}}, {{order.physicianState}} {{order.physicianZipcode}}</div>

    </div>
  </div>
  <div class="row">

    <div class="col-6">
      <strong class="w-100">Physician Phone number</strong>
      <p>{{order.physicianPhoneNumber}}</p>
    </div>
    <div class="col-6 flex-wrap">
      <strong class="w-100">Physician Fax number</strong>
      <p>{{order.physicianFaxNumber}}</p>
    </div>
  </div>
  <div class="row" *ngIf="order.specialInstructions?.length>0">
    <div class="col-12">
      <strong>Special Instructions</strong>
      {{order.specialInstructions}}
    </div>
  </div>
  <hr />
  <ng-container *ngIf="isAdmin">
    <div class="row mb-2 mt-2">
      <div class="col-5">
        <strong>QB Invoice Id</strong>
        <div class="input-group">
          <input type="text" [(ngModel)]="qbInvoiceInputValue" (ngModelChange)="onInvoiceIdChange()"
            placeholder="Enter invoice Id" />
          <button pButton label="Save" class="" [disabled]="!showQBInvoiceIdSubmit" *ngIf="showQBInvoiceIdSubmit"
            (click)="saveInvoiceId()"></button>
        </div>
      </div>
      <div class="col-3">
        <strong>Payment Status</strong>
        {{order.status}}
      </div>
      <div class="col-4">
        <strong>QB Pay Invoice Link</strong>
        <div class="d-flex align-items-center">
          <span class="d-inline-block text-truncate" style="max-width: 150px;">{{ order.invoiceLink ||''
            }}</span>&nbsp;<i class="bi bi-copy copybtn" (click)="copyInvoiceLink($event, order.invoiceLink)"
            [cdkCopyToClipboard]="invoiceLink" placement="top" ngbTooltip="Copied Link" triggers="click:blur"
            [closeDelay]="2000"></i>
        </div>
      </div>
    </div>
    <div class="row mb-2 mt-2">
      <div class="col-6">
        <strong>Amount Due</strong>
        <div class="input-group">
          @if(order.amountDue && order.amountDue.length>0){
          {{order.amountDue||'' | currency:'USD':'symbol':'1.2-2'}}
          } @else {
          <input type="text" [(ngModel)]="amountDueValue" (ngModelChange)="amountDueInputChange()"
            placeholder="Enter total amount due">
          <button pButton label="Save" class="mx-1" [disabled]="!showAmountDueSubmit" *ngIf="showAmountDueSubmit"
            (click)="saveAmountDue()"></button>
          }
        </div>
      </div>
      <div class="col-6">
        <strong>Due Date</strong>
        <div class="input-group">
          @if(order.dueDate && order.dueDate.length>0){
          {{order.dueDate||''}}
          } @else {
          <input type="text" [(ngModel)]="dueDateValue" (ngModelChange)="dueDateInputChange()" mask="99/99/9999"
            placeholder="99/99/9999" slotChar="mm/dd/yyyy">
          <button pButton label="Save" class="mx-1" [disabled]="!showDueDateSubmit" *ngIf="showDueDateSubmit"
            (click)="saveDueDate()"></button>
          }
        </div>
      </div>
    </div>
    <div class="row mb-2 mt-2">
      <div class="col-6">
        <strong>Paid Date</strong>
        <div class="input-group">
          @if(order.paidDate && order.paidDate.length>0 ){
          {{order.paidDate||''}}
          } @else {
          <input type="text" [(ngModel)]="paidDateValue" (ngModelChange)="paidDateInputChange()" mask="99/99/9999"
            placeholder="99/99/9999" slotChar="mm/dd/yyyy">
          <button pButton label="Save" class="mx-1" [disabled]="!showPaidDateSubmit" *ngIf="showPaidDateSubmit"
            (click)="savePaidDate()"></button>
          }
        </div>
      </div>

    </div>

  </ng-container>
  <div class="row mb-2 mt-2">
    <div class="col-6">
      <strong>Order Status</strong>
      <!-- {{ statusAccess(order.dartCareStatus)||'' }} -->
      <div class="input-group">
        <input type="text" [(ngModel)]="orderStatusInputValue" (ngModelChange)="onStatusInputChange()"
          placeholder="Enter order status" />
        <button pButton label="Save" class="mx-1" [disabled]="!showStatusSubmit" *ngIf="showStatusSubmit"
          (click)="updateOrderStatus()"></button>
      </div>
    </div>
    <div class="col-6">
      <strong>Tracking Number</strong>
      @if(order.trackingNumber && order.trackingNumber.length>0){
      {{ order.trackingNumber ||'' }}
      } @else {
      <div class="row justify-content-start">
        <div class="col-md-12">
          <div class="input-group">

            <input type="text" [(ngModel)]="trackingInputValue" (ngModelChange)="onTrackingInputChange()"
              placeholder="Enter tracking number">
            <button pButton label="Save" class="mx-1" [disabled]="!showTrackingNumberSubmit" *ngIf="showTrackingNumberSubmit"
              (click)="saveTrackingNumber()"></button>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
  <div class="row mb-2 mt-2">
    <div class="col-6">
      <strong>Shipping Date</strong>
      @if(order.shippingDate && order.shippingDate.length>0){
      {{ order.shippingDate ||'' }}
      } @else {
        <div class="input-group">
      <input type="text" [(ngModel)]="shippingDateInputValue" (ngModelChange)="shippingDateInputChange()"
        placeholder="Enter shipping date">
      <button pButton label="Save" class="mx-1" [disabled]="!showShippingDateSubmit" *ngIf="showShippingDateSubmit"
        (click)="saveShippingDate()"></button>
        </div>
      }
    </div>
    <div class="col-6">
      <strong>Delivery Date</strong>
      @if(order.deliveryDate && order.deliveryDate.length>0){
      {{ order.deliveryDate ||'' }}
      } @else {
        <div class="input-group">
      <input type="text" [(ngModel)]="deliveryDateInputValue" (ngModelChange)="deliveryDateInputChange()"
        placeholder="Enter delivery date">
      <button pButton label="Save" class="mx-1" [disabled]="!showDeliveryDateSubmit" *ngIf="showDeliveryDateSubmit"
        (click)="saveDeliveryDate()"></button>
        </div>
      }
    </div>
  </div>

  <div class="row mb-2 mt-2">
    <div class="col-6">
      <strong>Created Date</strong>
      {{order.orderDate | date:'M/d/yyyy hh:mm a'}}
    </div>
  </div>
  <ngx-spinner name="updatingOrder" bdColor="rgba(51,51,51,0.0)" color="#003BA4" [fullScreen]="false"
    type="ball-scale-pulse" size="medium">
    <h4 class="spinner-text">Updating Order</h4>
  </ngx-spinner>
